import { useMutation, useQuery } from 'react-query';
import { PunchInOutService } from '@api/PunchInOutService';
import { onDefaultError } from '../helpers/commonHelpers';
// Records
const useListPunchInOut = (
  [
    page_no,
    searchText,
    selectedStatus,
    selectedEmployee,
    selectedMachineName,
    selectedApprover,
    selectedPunchType,
    selectedDateRange
  ],
  onSuccess,
  onError = onDefaultError
) => {
  return useQuery(
    [
      'attendance-list',
      page_no,
      searchText,
      selectedStatus,
      selectedEmployee,
      selectedMachineName,
      selectedApprover,
      selectedPunchType,
      selectedDateRange
    ],
    PunchInOutService.listPunchInOut,
    {
      onSuccess,
      keepPreviousData: true,
      onError
    }
  );
};

// Get Approver
const useGetBioMetricApproverList = (params, onSuccess, onError = onDefaultError) => {
  return useQuery(['biometric-approver-list', params], PunchInOutService.approverList, {
    onSuccess,
    onError
  });
};

// Details
const useViewBioMetric = (id, onSuccess, onError = onDefaultError) => {
  return useQuery('biometric-view', () => PunchInOutService.viewBioMetric(id), {
    onSuccess,
    onError
  });
};

// Update
const useUpdateBioMetric = (onSuccess, onError = onDefaultError) => {
  return useMutation(PunchInOutService.updateBioMetric, {
    onSuccess,
    onError
  });
};

const useUpdateBioMetricStatus = (onSuccess, onError = onDefaultError) => {
  return useMutation(PunchInOutService.changeStatusBioMetric, {
    onSuccess,
    onError
  });
};

// Get Approver
const useGetBioMetricEmployeeList = (params, onSuccess, onError = onDefaultError) => {
  return useQuery(['biometric-employee-list', params], PunchInOutService.EmployeeList, {
    onSuccess,
    onError
  });
};

export {
  useListPunchInOut,
  useGetBioMetricApproverList,
  useViewBioMetric,
  useUpdateBioMetric,
  useUpdateBioMetricStatus,
  useGetBioMetricEmployeeList
};
