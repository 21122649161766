import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
const EditUserAddress = React.lazy(() => import('./EditUserAddress'));
const ViewUserAddress = React.lazy(() => import('./ViewUserAddress'));
const UserAddressRoutes = ({ t }) => {
  return [
    <Route key="edit_user_address" exact path="/edit/:id" element={<EditUserAddress t={t} />} />,
    <Route
      key="view_user_address"
      exact
      path="/view/:id/address"
      element={<ViewUserAddress t={t} />}
    />
  ];
};
UserAddressRoutes.propTypes = {
  t: PropTypes.func
};
export default UserAddressRoutes;
