import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
const ViewUserOther = React.lazy(() => import('./ViewUserOther'));
const UserOtherRoutes = ({ t }) => {
  return [
    <Route key="view_user_other" exact path="/view/:id/others" element={<ViewUserOther t={t} />} />
  ];
};
UserOtherRoutes.propTypes = {
  t: PropTypes.func
};
export default UserOtherRoutes;
