import client from '@libs/HttpClient';
class UserMasterService {
  static listUserRoles(request) {
    const page_no = request.queryKey[1] ?? '';
    const searchText = request.queryKey[2] ?? '';
    const status = request.queryKey[3] ?? '';
    const user_role = request.queryKey[4] ?? '';
    const user_type = request.queryKey[5] ?? '';
    const blood_group = request.queryKey[6] ?? '';
    const tshirt_size = request.queryKey[7] ?? '';
    const gender = request.queryKey[8] ?? '';
    const department = request.queryKey[9] ?? '';
    return client.get(
      `/user?search=${searchText}&page=${page_no}&status=${status}&user_role=${user_role}&user_type=${user_type}&blood_group=${blood_group}&tshirt_size=${tshirt_size}&gender=${gender}&department=${department}`
    );
  }
  static editUser(params) {
    return client.get(`/user/${params}/edit`);
  }
  static viewUser(params) {
    return client.get(`/user/${params}`);
  }
  static updateUser(request) {
    const id = request.get('id');
    return client.post(`/user/${id}?_method=PUT`, request, {
      headers: {
        'Content-type': 'multipart/form-data'
      }
    });
  }
  static addUser(request) {
    return client.post('/user', request);
  }
  static statusChangeUser(request) {
    return client.post(`/user/update-status/${request.id}`, request);
  }
  static deleteUser(request) {
    return client.delete(`/user/${request.id}`, request);
  }
  static getRoleList(request) {
    return client.get(`/user/role-list`, request);
  }
  static getRouteAccessList(request) {
    return client.get(`/user/get-route-accessList`, request);
  }
  static checkUserPermission(request) {
    const check_permission = request.queryKey[1];
    return client.get(`/user/check-user-permission?check_permission=${check_permission}`, request);
  }
  static branchList(request) {
    return client.get(`/user/branch-list`, request);
  }
  static departmentList(request) {
    return client.get(`/user/department-list`, request);
  }
  static updateUserAddress(request) {
    const id = request.get('id');
    return client.post(`/user/view/${id}/address?_method=PUT`, request);
  }
  static updateUserContact(request) {
    const id = request.get('id');
    return client.post(`/user/view/${id}/contact?_method=PUT`, request);
  }
  static updateUserApprover(request) {
    const id = request.get('id');
    return client.post(`/user/view/${id}/approver?_method=PUT`, request);
  }
  static updateUserOther(request) {
    const id = request.get('id');
    return client.post(`/user/view/${id}/other?_method=PUT`, request);
  }
}
export { UserMasterService };
