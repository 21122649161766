// React Dependencies
import { useState, React, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
// Component
import { Form, Card, Row, Col, Badge, Button } from 'react-bootstrap';

import 'quill-better-table/src/assets/quill-better-table.scss'; // Import the Better Table styles

// Asset
import '@assets/scss/page/_taskView.scss';
// Alert
import { toast } from 'react-toastify';
// moment
import moment from 'moment';
// API
import {
  useListTaskComment,
  useAddTaskComment,
  useUpdateTaskComment,
  useDeleteTaskComment
} from '@hooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faEdit, faFloppyDisk, faTrash, faXmark, faClose } from '@fortawesome/free-solid-svg-icons';

import { TNButton } from '@common/components';
// confirmAlert
import { confirmAlert } from 'react-confirm-alert';
import CustomReactQuillEditor from '../../../../../common/components/CustomReactQuillEditor';

const ListCommentPage = ({
  t,
  socket,
  getImageName,
  getProfileImageURL,
  shouldShowRoute,
  userData,
  popupImage,
  setPopupImage,
  projectTeamMembers
}) => {
  // Page number for work log
  const [commentPage, setCommentPage] = useState(1);
  const [hasMoreComments, setHasMoreComments] = useState(true);
  const [commentsData, setCommentsData] = useState([]);
  const [showEditor, setShowEditor] = useState(false);
  const [commentDescription, setCommentDescription] = useState('');
  const [projectTaskCommentIdValue, setProjectTaskCommentIdValue] = useState(null);
  const reactQuillRef = useRef(null);

  // Project ID And Task ID
  let { id, projectTaskId } = useParams();

  // Socket Initialization
  useEffect(() => {
    socket.on('get-latest-comment', () => {
      setCommentPage(1);
      refetchComment();
    });
  }, []);

  // Create Comment API
  const { mutate: createCommentData } = useAddTaskComment(
    (response) => {
      toast.success(response.message);
      setShowEditor(false);
      setCommentDescription('');
      if (commentPage == 1) {
        refetchComment();
      } else {
        setCommentPage(1);
      }
      socket.emit('comment-updated', '');
    },
    (error) => {
      console.log(error, 'Error');
    }
  );
  // Update Comment API
  const { mutate: updateCommentData } = useUpdateTaskComment(
    (response) => {
      toast.success(response.message);
      setShowEditor(false);
      setCommentDescription('');
      if (commentPage == 1) {
        refetchComment();
      } else {
        setCommentPage(1);
      }
      setProjectTaskCommentIdValue(null);
      socket.emit('comment-updated', '');
    },
    (error) => {
      console.log(error, 'Error');
    }
  );

  // Fetch Records with Pagination
  const {
    refetch: refetchComment,
    isLoading: commentLoading,
    isFetching
  } = useListTaskComment(
    [id, projectTaskId, commentPage],
    (res) => {
      let newCommentsData = [];

      // Check if there's data and handle pagination
      if (res?.data) {
        const { current_page, last_page } = res.data.pagination || {};

        // Update hasMoreComments based on pagination
        setHasMoreComments(current_page < last_page);

        // Process comments
        res?.data?.project_task_comment_list.forEach((object) => {
          let newCommentObject = { ...object };
          let commentDateTime = newCommentObject?.formatted_created_at;
          newCommentObject['commentDateTime'] = commentDateTime;

          let newUserObject = newCommentObject?.get_user_info ?? {};
          newUserObject['full_name'] = `${newUserObject?.first_name} ${newUserObject?.last_name}`;
          newUserObject['image_name'] = getImageName(
            newUserObject?.first_name,
            newUserObject?.last_name
          );
          newUserObject['profile_image'] = newUserObject?.profile_image
            ? getProfileImageURL(newUserObject?.user_id, newUserObject?.profile_image)
            : '';

          newCommentObject['get_user_info'] = newUserObject;
          newCommentObject['formatted_updated_at'] = newCommentObject?.formatted_updated_at;

          newCommentObject['images'] = extractImages(newCommentObject?.comment);
          newCommentsData.push(newCommentObject);
        });

        // If on the first page, set the comments data directly; otherwise, append new comments
        if (current_page === 1) {
          setCommentsData(newCommentsData);
        } else {
          setCommentsData((prevData) => [...prevData, ...newCommentsData]);
        }
      }
    },
    (error) => {
      console.log(error, 'Error');
    },
    {
      staleTime: 0, // Make data stale immediately
      cacheTime: 0 // Optional: clear cache right away
    }
  );

  useEffect(() => {
    refetchComment();
  }, [commentPage]);

  const loadMoreComments = () => {
    if (hasMoreComments && !commentLoading && !isFetching) {
      setCommentPage((prevPage) => prevPage + 1);
    }
  };

  const createComment = () => {
    let commentData = {
      project_id: id,
      project_task_id: projectTaskId,
      comment: commentDescription
    };
    createCommentData(commentData);
  };
  // Scroll To Comment
  const scroll = () => {
    const section = document.querySelector('#userCommentInputDiv');
    section.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };
  // Get Comment Data For Update
  const getCommentDataForUpdate = (commentData) => {
    setProjectTaskCommentIdValue(commentData?.project_task_comments_id);
    setCommentDescription(commentData?.comment);
    setShowEditor(true);
    scroll();
  };
  // Update Comment
  const updateComment = () => {
    let commentData = {
      project_id: id,
      project_task_id: projectTaskId,
      project_task_comments_id: projectTaskCommentIdValue,
      comment: commentDescription
    };
    updateCommentData(commentData);
  };
  // Delete Comment
  const { deleteTaskComment } = useDeleteTaskComment();
  const handleDeleteClick = (id, projectTaskId, projectTaskCommentId) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="alert-box">
            <FontAwesomeIcon
              className="alert-close"
              icon={faClose}
              onClick={() => {
                onClose();
              }}
            />
            <div className="alert-popup">
              <h2>{t('page.delete_task_status_type_alert_popup_message')}</h2>
              <TNButton
                className="table-delete-button"
                onClick={async () => {
                  try {
                    const response = await deleteTaskComment(
                      id,
                      projectTaskId,
                      projectTaskCommentId
                    );
                    if (response && response.message) {
                      toast.success(response.message);
                    }
                    onClose();
                    if (commentPage == 1) {
                      refetchComment();
                    } else {
                      setCommentPage(1);
                    }
                    socket.emit('comment-updated', '');
                  } catch (error) {
                    toast.error(error);
                  }
                }}>
                {t('page.alert_popup_yes_button')}
              </TNButton>
              <TNButton className="table-primary-button" onClick={onClose}>
                {t('page.alert_popup_no_button')}
              </TNButton>
            </div>
          </div>
        );
      }
    });
  };
  // Image Popup
  const extractImages = (content) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(content, 'text/html');
    return Array.from(doc.querySelectorAll('img')).map((img) => img.src);
  };

  return (
    <>
      <Col className="tabDiv" lg={12} xs={12}>
        {shouldShowRoute('projects.task.comment.store') && (
          <Row id="userCommentInputDiv" style={{ marginBottom: '10px' }}>
            <Col lg={1} xs={1} style={{ width: '4%' }}>
              {userData?.profile_image ? (
                <div
                  data-toggle="tooltip"
                  data-placement="top"
                  title={userData?.full_name}
                  className="profileImageWithImage">
                  <img src={userData?.profile_image} />
                </div>
              ) : (
                <div
                  data-toggle="tooltip"
                  data-placement="top"
                  title={userData?.full_name}
                  className="profileImageWithoutImage">
                  {userData?.image_name}
                </div>
              )}
            </Col>
            <Col lg={6} xs={6}>
              {!showEditor ? (
                <Form.Group>
                  <Form.Control
                    className="editorPlaceHolder"
                    placeholder={t('page.task_comment_placeholder')}
                    onClick={() => {
                      setShowEditor(true);
                    }}
                  />
                </Form.Group>
              ) : (
                <>
                  <Form.Group>
                    <CustomReactQuillEditor
                      t={t}
                      ref={reactQuillRef}
                      value={commentDescription}
                      taggingUsers={projectTeamMembers}
                      fileUploadDir="project/task/comment"
                      onChange={(content) => {
                        setCommentDescription(content);
                      }}
                      placeholder={t('page.react-quill-placeholder')}
                      onFocus={() => console.log('Editor focused')}
                      onBlur={() => console.log('Editor blurred')}
                      style={{
                        fontSize: '16px' // Default font size for editor container
                      }}
                    />
                  </Form.Group>
                  <Button
                    type="button"
                    onClick={() => {
                      projectTaskCommentIdValue ? updateComment() : createComment();
                    }}
                    className="btn btn-primary editorButton">
                    {t('page.task_comment_create')}
                  </Button>
                  <span
                    className="editorCancelButton"
                    onClick={() => {
                      setShowEditor(false);
                      setCommentDescription('');
                    }}>
                    {t('page.cancel_button_text')}
                  </span>
                </>
              )}
            </Col>
          </Row>
        )}
        {commentsData &&
          commentsData?.map((object, index) => (
            <Row className="userComment" key={`user_comments_${index}`}>
              <Col lg={1} xs={1} style={{ width: '4%' }}>
                {object?.get_user_info?.profile_image ? (
                  <div
                    data-toggle="tooltip"
                    data-placement="top"
                    title={object?.get_user_info?.full_name}
                    className="profileImageWithImage">
                    <img src={object?.get_user_info?.profile_image} />
                  </div>
                ) : (
                  <div
                    data-toggle="tooltip"
                    data-placement="top"
                    title={object?.get_user_info?.full_name}
                    className="profileImageWithoutImage">
                    {object?.get_user_info?.image_name}
                  </div>
                )}
              </Col>
              <Col lg={6} xs={6}>
                <div className="userInformation">
                  <h5>{object?.get_user_info?.full_name}</h5>
                  <p>
                    {object?.commentDateTime}
                    {object?.formatted_updated_at != null && object?.formatted_updated_at != '' ? (
                      <b style={{ fontWeight: 'bold' }}>
                        <sup>&nbsp;{t('page.edited')}</sup>
                      </b>
                    ) : (
                      ''
                    )}
                    {shouldShowRoute('projects.task.comment.destroy') &&
                    (userData?.user_id == object?.get_user_info?.user_id ||
                      userData?.user_type == 1) ? (
                      <>
                        <FontAwesomeIcon
                          className="alert-close actionButton deleteButton"
                          icon={faTrash}
                          onClick={() => {
                            handleDeleteClick(id, projectTaskId, object?.project_task_comments_id);
                          }}
                        />
                      </>
                    ) : (
                      ''
                    )}
                    {shouldShowRoute('projects.task.comment.update') &&
                    userData?.user_id == object?.get_user_info?.user_id ? (
                      <>
                        <FontAwesomeIcon
                          className="alert-close actionButton editButton"
                          icon={faEdit}
                          onClick={() => {
                            getCommentDataForUpdate(object);
                          }}
                        />
                      </>
                    ) : (
                      ''
                    )}
                  </p>
                </div>
                <h6
                  className="commentInfo react_quill_content_data"
                  dangerouslySetInnerHTML={{ __html: object.comment }}></h6>
                <Row className="commentBoxImage">
                  {object?.images &&
                    object?.images?.map((object, index) => {
                      return (
                        <Col className="imageBox" key={`image_box_${index}`} lg={2} xs={2}>
                          <img
                            onClick={() => {
                              setPopupImage(object);
                            }}
                            src={object}
                          />
                        </Col>
                      );
                    })}
                </Row>
              </Col>
            </Row>
          ))}
        {(commentLoading || isFetching) && <p>{t('page.loading')}</p>}
        {hasMoreComments && !(commentLoading || isFetching) && (
          <button
            className="work-log-button-load_more"
            onClick={loadMoreComments}
            disabled={commentLoading || isFetching}>
            {t('page.load_more')}
          </button>
        )}
        {commentsData.length <= 0 && !commentLoading && !isFetching && (
          <p>{t('page.comment_data_not_found')}</p>
        )}
      </Col>
    </>
  );
};
ListCommentPage.propTypes = {
  t: PropTypes.func,
  getImageName: PropTypes.func,
  getProfileImageURL: PropTypes.func
};
export default ListCommentPage;
