import client from '@libs/HttpClient';

class PunchInOutService {
  //Attendance Master
  static listPunchInOut(request) {
    const page_no = request.queryKey[1];
    const searchText = request.queryKey[2];
    const selectedStatus = request.queryKey[3];
    const selectedEmployee = request.queryKey[4];
    const selectedMachineName = request.queryKey[5];
    const selectedApproved = request.queryKey[6];
    const selectedPunchType = request.queryKey[7];
    const selectedDateRange = request.queryKey[8];
    console.log('request', request);
    return client.get(
      `/biometric?search=${searchText}&page=${page_no}&selectedStatus=${selectedStatus}&selectedEmployee=${selectedEmployee}&selectedMachineName=${selectedMachineName}&selectedApproved=${selectedApproved}&selectPunchType=${selectedPunchType}&selectedDateRange=${selectedDateRange}`,
      request
    );
  }

  static approverList(params) {
    const { type } = params.queryKey[1];
    return client.get(`/biometric-approver-list?type=${type}`);
  }

  static viewBioMetric(params) {
    return client.get(`/biometric/${params}/edit`);
  }

  static updateBioMetric(request) {
    return client.put(`/biometric/${request.id}`, request);
  }

  static changeStatusBioMetric(request) {
    return client.patch(`/biometric/change-status/${request.id}`, request);
  }

  static EmployeeList() {
    return client.get(`/biometric-approver-employee-list`);
  }
}

export { PunchInOutService };
