import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { GeneralSetting } from './GeneralSettingPage';
import { BiometricSetting } from './BiometricSetting/BiometricSettingPage';

const SettingRoutes = ({ t }) => {
  return (
    <Routes key={'routes'}>
      <Route
        key={'general_setting'}
        index
        exact
        path="/general"
        element={<GeneralSetting t={t} />}
      />
      <Route
        key={'biometric_setting'}
        index
        exact
        path="/biometric"
        element={<BiometricSetting t={t} />}
      />
    </Routes>
  );
};
SettingRoutes.propTypes = {
  t: PropTypes.func
};
export default SettingRoutes;
