import { Nav } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';

const SettingNavigation = (props) => {
  const { t, routeName, activeLabel } = props;
  const navigateArray = [
    {
      label: t('page.general_setting_tab_general_setting'),
      link: `/setting/general`,
      identity: 'general_setting',
      permission: 'setting.index'
    },
    {
      label: t('page.general_setting_tab_biometric_setting'),
      link: `/setting/biometric`,
      identity: 'biometric_setting',
      permission: 'setting.biometric.index'
    }
  ];

  return (
    <Nav className="tab-navigation">
      {navigateArray.map(
        (navigateObject, index) =>
          routeName.includes(navigateObject.permission) && (
            <>
              <Nav.Item key={index}>
                <NavLink
                  className={activeLabel !== navigateObject.identity ? 'inactiveLink' : ''}
                  to={navigateObject.link}>
                  {navigateObject.label}
                </NavLink>
              </Nav.Item>
            </>
          )
      )}
    </Nav>
  );
};
SettingNavigation.propTypes = {
  t: PropTypes.func,
  routeName: PropTypes.array,
  activeLabel: PropTypes.string
};
export { SettingNavigation };
