import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
const ListPunchInOutPage = React.lazy(() => import('./ListPunchInOutPage'));
const EditPunchInOutPage = React.lazy(() => import('./EditPunchInOutPage'));
const ViewPunchInOutPage = React.lazy(() => import('./ViewPunchInOutPage'));
const PunchInOutRoute = ({ t }) => {
  return (
    <Routes>
      <Route index exact path="/list" element={<ListPunchInOutPage t={t} />} />
      <Route index exact path="/edit/:id" element={<EditPunchInOutPage t={t} />} />
      <Route index exact path="/view/:id" element={<ViewPunchInOutPage t={t} />} />
      <Route path="*" element={<Navigate replace to="/404" />} />
    </Routes>
  );
};
PunchInOutRoute.propTypes = {
  t: PropTypes.func
};
export default PunchInOutRoute;
