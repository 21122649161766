import * as Yup from 'yup';
let validationSchema = Yup.object({
  biometric_left_side_entry_machine_code: Yup.string(
    'page.biometric_setting_left_side_entry_machine_code_required'
  )
    .trim('page.biometric_setting_left_side_entry_machine_code_required')
    .required('page.biometric_setting_left_side_entry_machine_code_required'),
  biometric_left_side_exits_machine_code: Yup.string(
    'page.biometric_setting_left_side_exits_machine_code_required'
  )
    .trim('page.biometric_setting_left_side_exits_machine_code_required')
    .required('page.biometric_setting_left_side_exits_machine_code_required'),
  biometric_right_side_entry_machine_code: Yup.string(
    'page.biometric_setting_right_side_entry_machine_code_required'
  )
    .trim('page.biometric_setting_right_side_entry_machine_code_required')
    .required('page.biometric_setting_right_side_entry_machine_code_required'),
  biometric_right_side_exits_machine_code: Yup.string(
    'page.biometric_setting_right_side_exits_machine_code_required'
  )
    .trim('page.biometric_setting_right_side_exits_machine_code_required')
    .required('page.biometric_setting_right_side_exits_machine_code_required')
});

export default validationSchema;
