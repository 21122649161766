// React Dependencies
import { React, useRef, useState } from 'react';
// Common Dependencies
import PropTypes from 'prop-types';
import { NavLink, useNavigate } from 'react-router-dom';
// Component
import { Card, Col, Nav, Row, Form, Button } from 'react-bootstrap';
import { TNButton } from '@common/components/TNButton';

// Alert
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';
// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';

// Validation
import { useFormik } from 'formik';
import { useRouteAccessList } from '@hooks';
import validationSchema from './BiometricSettingValidation';

// Asset
import '../../../../assets/scss/page/_general_setting.scss';
import CommonSEOTitle from '../../../../helpers/CommonSEOTitle';
import { SettingNavigation } from '../Navigation/SettingNavigation';
import { useGetAllSettingData, useStoreBioMetricSettingData } from '../../../../hooks';

const BiometricSetting = ({ t }) => {
  // Navigate And Dispatch
  const navigate = useNavigate();

  // States
  const [routeName, setRouteNames] = useState([]);
  // Route Access List
  useRouteAccessList((res) => {
    setRouteNames(res.data.route_name);
  });
  const shouldShowRoute = (route) => {
    if (routeName) {
      return routeName.includes(route);
    }
  };

  // Formik Initialization
  const formik = useFormik({
    initialValues: {
      biometric_left_side_entry_machine_code: '',
      biometric_left_side_exits_machine_code: '',
      biometric_right_side_entry_machine_code: '',
      biometric_right_side_exits_machine_code: ''
    },
    validationSchema,
    onSubmit: (values) => {
      doStoreSettingData(values);
    }
  });

  // Setting Data Initialization
  useGetAllSettingData(
    ({ data: setting }) => {
      formik.values.biometric_left_side_entry_machine_code =
        setting?.biometric_left_side_entry_machine_code;
      formik.values.biometric_left_side_exits_machine_code =
        setting?.biometric_left_side_exits_machine_code;
      formik.values.biometric_right_side_entry_machine_code =
        setting?.biometric_right_side_entry_machine_code;
      formik.values.biometric_right_side_exits_machine_code =
        setting?.biometric_right_side_exits_machine_code;
    },
    (error) => {
      if (error?.data?.redirect == true) {
        toast.error(error.message);
        navigate('/dashboard');
      } else {
        toast.error(error.message);
      }
    }
  );

  // Cancel Form
  const handleCancel = () => {
    if (formik.dirty && formik.dirty !== undefined) {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="alert-box">
              <FontAwesomeIcon
                className="alert-close"
                icon={faClose}
                onClick={() => {
                  onClose();
                }}
              />
              <div className="alert-popup">
                <h5
                  dangerouslySetInnerHTML={{
                    __html: t('page.reset_alert_popup_message')
                  }}></h5>
                <Button
                  className="table-delete-button"
                  onClick={() => {
                    onClose();
                    navigate(`/dashboard/`, { replace: true });
                  }}>
                  {t('page.alert_popup_yes_button')}
                </Button>
                <Button className="table-primary-button" onClick={onClose}>
                  {t('page.alert_popup_no_button')}
                </Button>
              </div>
            </div>
          );
        }
      });
    } else {
      navigate(`/dashboard/`, { replace: true });
    }
  };

  // Fetch Settings Data
  const { mutate: doStoreSettingData } = useStoreBioMetricSettingData(
    (response) => {
      toast.success(response.message);
      setTimeout(function () {
        navigate('/dashboard');
      }, 1500);
    },
    (error) => {
      toast.error(error.message);
    }
  );

  return (
    <>
      <CommonSEOTitle title={t('page.general_setting_tab_biometric_setting')} />
      <SettingNavigation t={t} routeName={routeName} activeLabel={'biometric_setting'} />
      <Card className="inner-box">
        <h1 className="page-heading-center">{t('page.biometric_setting_header_text')}</h1>
        <div className="settings">
          <div className="general-setting">
            <Form onSubmit={formik.handleSubmit}>
              <Row>
                <Col lg={6} md={6} sm={12}>
                  <Form.Group>
                    <Form.Label className="field-label field-label-top">
                      {t('page.biometric_setting_left_side_entry_machine_code_label')}
                    </Form.Label>
                    <Form.Control
                      className={
                        ' ' +
                        (formik.touched.biometric_left_side_entry_machine_code &&
                        formik.errors.biometric_left_side_entry_machine_code
                          ? 'form-field-error'
                          : formik.touched.biometric_left_side_entry_machine_code &&
                              !formik.errors.biometric_left_side_entry_machine_code
                            ? 'form-field-success'
                            : '')
                      }
                      type="text"
                      name="biometric_left_side_entry_machine_code"
                      placeholder={t(
                        'page.biometric_setting_left_side_entry_machine_code_placeholder'
                      )}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik?.values?.biometric_left_side_entry_machine_code ?? ''}
                    />
                    <div className="form-field-error-text">
                      {formik.touched.biometric_left_side_entry_machine_code &&
                      formik.errors.biometric_left_side_entry_machine_code ? (
                        <div>{t(formik.errors.biometric_left_side_entry_machine_code)}</div>
                      ) : null}
                    </div>
                  </Form.Group>
                </Col>
                <Col lg={6} md={6} sm={12}>
                  <Form.Group>
                    <Form.Label className="field-label field-label-top">
                      {t('page.biometric_setting_left_side_exits_machine_code_label')}
                    </Form.Label>
                    <Form.Control
                      className={
                        ' ' +
                        (formik.touched.biometric_left_side_exits_machine_code &&
                        formik.errors.biometric_left_side_exits_machine_code
                          ? 'form-field-error'
                          : formik.touched.biometric_left_side_exits_machine_code &&
                              !formik.errors.biometric_left_side_exits_machine_code
                            ? 'form-field-success'
                            : '')
                      }
                      type="text"
                      name="biometric_left_side_exits_machine_code"
                      placeholder={t(
                        'page.biometric_setting_left_side_exits_machine_code_placeholder'
                      )}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik?.values?.biometric_left_side_exits_machine_code ?? ''}
                    />
                    <div className="form-field-error-text">
                      {formik.touched.biometric_left_side_exits_machine_code &&
                      formik.errors.biometric_left_side_exits_machine_code ? (
                        <div>{t(formik.errors.biometric_left_side_exits_machine_code)}</div>
                      ) : null}
                    </div>
                  </Form.Group>
                </Col>
                <Col lg={6} md={6} sm={12}>
                  <Form.Group>
                    <Form.Label className="field-label field-label-top">
                      {t('page.biometric_setting_right_side_entry_machine_code_label')}
                    </Form.Label>
                    <Form.Control
                      className={
                        ' ' +
                        (formik.touched.biometric_right_side_entry_machine_code &&
                        formik.errors.biometric_right_side_entry_machine_code
                          ? 'form-field-error'
                          : formik.touched.biometric_right_side_entry_machine_code &&
                              !formik.errors.biometric_right_side_entry_machine_code
                            ? 'form-field-success'
                            : '')
                      }
                      type="text"
                      name="biometric_right_side_entry_machine_code"
                      placeholder={t(
                        'page.biometric_setting_right_side_entry_machine_code_placeholder'
                      )}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik?.values?.biometric_right_side_entry_machine_code ?? ''}
                    />
                    <div className="form-field-error-text">
                      {formik.touched.biometric_right_side_entry_machine_code &&
                      formik.errors.biometric_right_side_entry_machine_code ? (
                        <div>{t(formik.errors.biometric_right_side_entry_machine_code)}</div>
                      ) : null}
                    </div>
                  </Form.Group>
                </Col>
                <Col lg={6} md={6} sm={12}>
                  <Form.Group>
                    <Form.Label className="field-label field-label-top">
                      {t('page.biometric_setting_right_side_exits_machine_code_label')}
                    </Form.Label>
                    <Form.Control
                      className={
                        ' ' +
                        (formik.touched.biometric_right_side_exits_machine_code &&
                        formik.errors.biometric_right_side_exits_machine_code
                          ? 'form-field-error'
                          : formik.touched.biometric_right_side_exits_machine_code &&
                              !formik.errors.biometric_right_side_exits_machine_code
                            ? 'form-field-success'
                            : '')
                      }
                      type="text"
                      name="biometric_right_side_exits_machine_code"
                      placeholder={t(
                        'page.biometric_setting_right_side_exits_machine_code_placeholder'
                      )}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik?.values?.biometric_right_side_exits_machine_code ?? ''}
                    />
                    <div className="form-field-error-text">
                      {formik.touched.biometric_right_side_exits_machine_code &&
                      formik.errors.biometric_right_side_exits_machine_code ? (
                        <div>{t(formik.errors.biometric_right_side_exits_machine_code)}</div>
                      ) : null}
                    </div>
                  </Form.Group>
                </Col>
              </Row>
              <div className="primary-button">
                <span className="link-center" onClick={handleCancel}>
                  {t('page.general_setting_cancel_link')}
                </span>
                <TNButton
                  type="submit"
                  isdirtyform={formik.dirty && formik.dirty !== undefined ? 1 : 0}>
                  {' '}
                  {t('page.general_setting_save_button')}
                </TNButton>
              </div>
            </Form>
          </div>
        </div>
      </Card>
    </>
  );
};
BiometricSetting.propTypes = {
  t: PropTypes.func
  //   isdirtyform: PropTypes.any
};
export { BiometricSetting };
